@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/emotional-baggage');
@import url('https://fonts.cdnfonts.com/css/frost-land');

.emotiona{
    font-family: 'Emotional Baggage', sans-serif;
}
.frost{
    font-family: 'Frost Land', sans-serif;
}

